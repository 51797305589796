html{
  scroll-behavior: smooth
}
*{
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
a{
  text-decoration: none;
}
a:hover{
  color: #FFF;
}

h1, h2, h3, h4, h5, h6, a{
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child{
  margin-bottom: 25px;
}

body{
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
  margin: 0px;
}

/*Menu active*/
.ap_mnDAle{
  transition: all 0.2s ease-out;
  background-color: #322D6A;
  border-radius: 5px;
}
/*Menu active*/
.sm_mnDAle{
  transition: all 0.2s ease-out;
  background-color: #00c3ff;
  border-radius: 5px;
}

.error-text{
  position: absolute;
  z-index: 99999999;
  top: -24px;
  border-radius: 10px 0px 0px 0px;
  right: 0px;
  background: red;
  font-size: 10px;
  color: #FFF;
  padding: 5px 10px;
}
.helper-text{
  position: absolute;
  z-index: 99999999;
  top: -24px;
  right: 0px;
  font-size: 10px;
  color: #2F2861;
  padding: 5px 10px;
}